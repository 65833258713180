import React from "react";
import "./styles.css";
import TileBanner from "../../common-components/tileBanner/TileBanner";
import Sandbox from "../../common-components/sandbox/Sandbox";

const Home = () => {
  return (
    <div className="home-container">
      <TileBanner />
      <div className="home-text">
        World is in constant state of Formation and Destruction
      </div>
      <div className="home-text">
        Here is the list of sandboxes of some important problems asked in
        Frontend Interview
      </div>
      <div className="home-sandboxes">
        {/* <Sandbox
          text={"Tic Tac Toe"}
          link={"https://codesandbox.io/p/sandbox/tic-tac-toe-of80ns"}
        /> */}
        <Sandbox
          text={"Bishop on Chessboard"}
          link={"https://codesandbox.io/p/sandbox/bishop-on-chessboard-jdnrk6"}
        />
        <Sandbox
          text={"Star Rating"}
          link={"https://codesandbox.io/p/sandbox/exciting-hertz-q3qevm"}
        />
      </div>
    </div>
  );
};
export default Home;
