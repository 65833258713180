import React from "react";
import "./styles.css";
const TileBanner = () => {
  return (
    <div className="banner">
      {[1, 2, 3, 4].map((i) => {
        return (
          <div className="tileRow">
            {[1, 2, 3, 4].map((j) => {
              return <div className="tile" />;
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TileBanner;
