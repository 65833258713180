import React from "react";
import { Link, Outlet } from "react-router-dom";
import "./styles.css";
import LabradorSvg from "../../assets/svgs/labradorSvg";

const Header = () => {
  return (
    <div className="header">
      <span>
        <div className={"header-item-left"}>
          <span className="header-item">Rishabh</span>
        </div>
      </span>
      <span>
        <div>
          <span className="header-item">About</span>
        </div>
      </span>
    </div>
  );
};
const Layout = () => {
  return (
    <div className="body">
      <Header />
      <div className="outlet-container">
        <div className="outlet-style">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
