import React from "react";
import "./styles.css";

const Sandbox = ({ text, link }) => {
  const handleSanboxClick = () => {
    window.open(link, "_blank");
  };
  return (
    <div className="sandbox-outer-layer centerise">
      <div className="sandbox-middle-layer centerise">
        <div
          className="sandbox-inner-layer centerise"
          onClick={handleSanboxClick}
        >
          <div>{text}</div>
        </div>
      </div>
    </div>
  );
};

export default Sandbox;
